// actions.js (project actions)
import axiosServices from 'utils/axios';
import { SNACKBAR_OPEN } from 'store/actions';

// Actions

export const ADD_ITEM = '@kanban/ADD_ITEM';
export const EDIT_ITEM = '@kanban/EDIT_ITEM';
export const DELETE_ITEM = '@kanban/DELETE_ITEM';
export const UPDATE_COLUMN_ITEM_ORDER = '@kanban/UPDATE_COLUMN_ITEM_ORDER';
export const SELECT_ITEM = '@kanban/SELECT_ITEM';
export const FETCH_ITEM_COMMENTS = '@kanban/FETCH_ITEM_COMMENTS';
export const EDIT_ITEM_COMMENT = '@kanban/EDIT_ITEM_COMMENT';
export const ADD_ITEM_COMMENT = '@kanban/ADD_ITEM_COMMENT';
export const GET_ITEMS_SUCCESS = '@kanban/FETCH_ITEMS_SUCCESS';
export const GET_ITEMS_FETCH = '@kanban/GET_ITEMS_FETCH';
export const DELETE_ITEM_COMMENT = '@kanban/DELETE_ITEM_COMMENT';

// Action Creators

// ---------------------------Items--------------------------------------

export const fetchItems = (projectId) => async (dispatch) => {
    try {
        const response = await axiosServices.get(`/api/tasks?projectId=${projectId}`);

        dispatch({
            type: GET_ITEMS_SUCCESS,
            payload: { items: response.data.data.docs }
        });
    } catch (error) {
        console.error('Error fetching internal-tasks:', error);
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const fetchProjectItems = (projectId) => async (dispatch) => {
    dispatch({
        type: GET_ITEMS_FETCH
    });
    try {
        const response = await axiosServices.get(`/api/tasks?projectId=${projectId}`);

        dispatch({
            type: GET_ITEMS_SUCCESS,
            payload: { items: response.data.data.docs }
        });
    } catch (error) {
        console.error('Error fetching tasks:', error);
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const addItem = (itemData) => async (dispatch) => {
    try {
        const response = await axiosServices.post('/api/tasks', itemData);

        const newItem = response.data.data;

        dispatch({
            type: ADD_ITEM,
            payload: { columnId: newItem.status, item: newItem }
        });
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Tasku u shtua me sukses!',
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'success'
        });
    } catch (error) {
        console.error('Error adding task:', error);
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const editItem = (itemId, itemData) => async (dispatch) => {
    try {
        const response = await axiosServices.patch(`/api/tasks/${itemId}`, itemData);

        dispatch({
            type: EDIT_ITEM,
            payload: { item: response.data.data }
        });
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Tasku u përditesua me sukses!',
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'success'
        });
    } catch (error) {
        console.error('Error editing task:', error);
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const editItemStatusSubcontractor = (itemId, status) => async (dispatch) => {
    try {
        const response = await axiosServices.patch(`/api/tasks/${itemId}/status`, { status });

        dispatch({
            type: EDIT_ITEM,
            payload: { item: response.data }
        });
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Tasku u përditesua me sukses!',
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'success'
        });
    } catch (error) {
        console.error('Error editing item status:', error);
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const editItemStatus = (itemId, currentStatus) => async (dispatch) => {
    try {
        const newStatus = currentStatus === 'TODO' ? 'DONE' : 'TODO';

        const response = await axiosServices.patch(`/api/tasks/${itemId}/status`, { status: newStatus });

        dispatch({
            type: EDIT_ITEM,
            payload: { item: response.data }
        });
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Tasku u përditesua me sukses!',
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'success'
        });
    } catch (error) {
        console.error('Error editing item status:', error);
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const deleteItem = (itemId) => async (dispatch) => {
    try {
        await axiosServices.patch(`/api/tasks/${itemId}`, { isActive: false });

        dispatch({
            type: DELETE_ITEM,
            payload: { itemId }
        });
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Tasku u fshi me sukses!',
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'success'
        });
    } catch (error) {
        console.error('Error deleting task:', error);
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const updateColumnItemOrder = (columnId, newOrder) => (dispatch) => {
    dispatch({
        type: UPDATE_COLUMN_ITEM_ORDER,
        payload: { columnId, newOrder }
    });
};

export const selectItem = (itemId) => (dispatch) => {
    dispatch({
        type: SELECT_ITEM,
        payload: { itemId }
    });
};

// ---------------------------Item Comments--------------------------------------

export const fetchItemComments = (itemId) => async (dispatch) => {
    try {
        const response = await axiosServices.get(`/api/comments/task/${itemId}?isActive=true`);

        dispatch({
            type: FETCH_ITEM_COMMENTS,
            payload: { comments: response.data.data.docs }
        });
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const addItemComment = (itemId, commentData) => async (dispatch) => {
    try {
        const response = await axiosServices.post(`/api/comments/${itemId}`, commentData);
        dispatch({
            type: ADD_ITEM_COMMENT,
            payload: { comment: response.data.data.docs }
        });
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Komenti u shtua me sukses!',
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'success'
        });
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const deleteItemComment = (commentId) => async (dispatch) => {
    try {
        await axiosServices.patch(`/api/comments/${commentId}`, { isActive: false });
        dispatch({
            type: DELETE_ITEM_COMMENT,
            payload: { commentId }
        });
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Komenti u fshi me sukses!',
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'success'
        });
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};
