// third-party

import { IconLayoutKanban, IconClipboardData, IconBrandTrello } from '@tabler/icons';

// constant
const icons = {
    IconLayoutKanban,
    IconClipboardData,
    IconBrandTrello
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const subcontractors = {
    id: 'dashboard',
    title: 'Ballina',
    type: 'group',
    children: [
        {
            id: 'projektet',
            title: 'Projektet',
            type: 'item',
            url: '/projects',
            icon: icons.IconLayoutKanban,
            breadcrumbs: false
        },
        {
            id: 'serviset',
            title: 'Serviset',
            type: 'item',
            url: '/allServices',
            icon: icons.IconClipboardData
        }
    ]
};

export default subcontractors;
