// third-party

// assets
import {
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconLayoutKanban,
    IconCalendar,
    IconAirConditioning,
    IconUserCheck,
    IconUsers
} from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconLayoutKanban,
    IconCalendar,
    IconAirConditioning,
    IconUserCheck,
    IconUsers
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'sample-docs-roadmap',
    title: 'Të tjera',
    type: 'group',
    children: [
        {
            id: 'calendar',
            title: 'Kalendari',
            type: 'item',
            url: '/calendar',
            icon: icons.IconCalendar
        }
    ]
};

export default other;
