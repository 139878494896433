import other from './other';
import dashboard from './dashboard';
import subcontractors from './subcontractors';
import clients from './clients';
import users from './users';
import employees from './employees';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: {
        dashboard: [dashboard],
        subcontractors: [subcontractors],
        employees: [employees],
        clients: [clients],
        users: [users],
        other: [other]
    }
};

export default menuItems;
