import {
    ADD_INTERNAL_PROJECT,
    EDIT_INTERNAL_PROJECT,
    DELETE_INTERNAL_PROJECT,
    FETCH_INTERNAL_PROJECTS,
    FETCH_INTERNAL_PROJECT_TEMPLATES
} from './internalProjectActions';

const initialState = {
    internalprojects: [],
    templates: []
};

const internalProjectReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_INTERNAL_PROJECTS: {
            const newStateAdd = {
                internalprojects: action.payload.projects
            };

            return newStateAdd;
        }
        case ADD_INTERNAL_PROJECT: {
            const newStateAdd = {
                ...state,
                internalprojects: [...state.internalprojects, action.payload.internalprojects]
            };

            localStorage.setItem('projectState', JSON.stringify(newStateAdd));

            return newStateAdd;
        }

        case EDIT_INTERNAL_PROJECT: {
            const updatedProjects = state.internalprojects.map((project) =>
                project.id === action.payload.project.id ? action.payload.project : project
            );

            const newStateEdit = {
                ...state,
                internalprojects: updatedProjects
            };

            return newStateEdit;
        }

        case DELETE_INTERNAL_PROJECT: {
            const newStateDelete = {
                ...state,
                internalprojects: state.internalprojects.filter((project) => project.id !== action.payload.projectId)
            };

            localStorage.setItem('projectState', JSON.stringify(newStateDelete));

            return newStateDelete;
        }
        case FETCH_INTERNAL_PROJECT_TEMPLATES: {
            const newState = {
                ...state,
                templates: action.payload.templates.data
            };
            return newState;
        }

        default:
            return state;
    }
};

export default internalProjectReducer;
