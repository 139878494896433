// actions.js (project actions)
import axiosServices from 'utils/axios';
import { SNACKBAR_OPEN } from 'store/actions';

// Actions

export const ADD_INTERNAL_ITEM = '@internalKanban/ADD_INTERNAL_ITEM';
export const EDIT_INTERNAL_ITEM = '@internalKanban/EDIT_INTERNAL_ITEM';
export const DELETE_INTERNAL_ITEM = '@internalKanban/DELETE_INTERNAL_ITEM';
export const UPDATE_COLUMN_INTERNAL_ITEM_ORDER = '@internalKanban/UPDATE_COLUMN_INTERNAL_ITEM_ORDER';
export const SELECT_INTERNAL_ITEM = '@internalKanban/SELECT_INTERNAL_ITEM';
export const FETCH_INTERNAL_ITEM_COMMENTS = '@internalKanban/FETCH_INTERNAL_ITEM_COMMENTS';
export const EDIT_INTERNAL_ITEM_COMMENT = '@internalKanban/EDIT_INTERNAL_ITEM_COMMENT';
export const ADD_INTERNAL_ITEM_COMMENT = '@internalKanban/ADD_INTERNAL_ITEM_COMMENT';
export const FETCH_INTERNAL_ITEMS_SUCCESS = '@internalKanban/FETCH_INTERNAL_ITEMS';
export const GET_INTERNAL_ITEMS_FETCH = '@internalKanban/GET_INTERNAL_ITEMS_FETCH';
export const DELETE_INTERNAL_ITEM_COMMENT = '@internalKanban/DELETE_INTERNAL_ITEM_COMMENT';

// Action Creators

// ---------------------------InternalItems--------------------------------------

export const fetchInternalItems = (projectId) => async (dispatch) => {
    try {
        const response = await axiosServices.get(`/api/internal-tasks?projectId=${projectId}`);

        dispatch({
            type: FETCH_INTERNAL_ITEMS_SUCCESS,
            payload: { internalItems: response.data.data.docs }
        });
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const fetchInternalProjectItems = (projectId) => async (dispatch) => {
    dispatch({
        type: GET_INTERNAL_ITEMS_FETCH
    });
    try {
        const response = await axiosServices.get(`/api/internal-tasks?projectId=${projectId}`);

        dispatch({
            type: FETCH_INTERNAL_ITEMS_SUCCESS,
            payload: { internalItems: response.data.data.docs }
        });
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const addInternalItem = (columnId, itemData) => async (dispatch) => {
    try {
        const response = await axiosServices.post('/api/internal-tasks/create', itemData);

        const newInternalItem = response.data.data;

        dispatch({
            type: ADD_INTERNAL_ITEM,
            payload: { columnId, item: newInternalItem }
        });
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Tasku u shtua me sukses!',
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'success'
        });
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const editInternalItem = (itemId, itemData) => async (dispatch) => {
    try {
        const response = await axiosServices.patch(`/api/internal-tasks/${itemId}`, itemData);

        dispatch({
            type: EDIT_INTERNAL_ITEM,
            payload: { item: response.data.data }
        });
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Tasku u përditesua me sukses!',
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'success'
        });
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const editInternalItemStatus = (itemId, currentStatus) => async (dispatch) => {
    try {
        const newStatus = currentStatus === 'TODO' ? 'DONE' : 'TODO';

        const response = await axiosServices.patch(`/api/internal-tasks/${itemId}`, { status: newStatus });

        dispatch({
            type: EDIT_INTERNAL_ITEM,
            payload: { item: response.data }
        });
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Tasku u përditesua me sukses!',
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'success'
        });
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const deleteInternalItem = (itemId) => async (dispatch) => {
    try {
        await axiosServices.patch(`/api/internal-tasks/${itemId}`, { isActive: false });

        dispatch({
            type: DELETE_INTERNAL_ITEM,
            payload: { itemId }
        });
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Tasku u fshi me sukses!',
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'success'
        });
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const updateColumnInternalItemOrder = (columnId, newOrder) => (dispatch) => {
    dispatch({
        type: UPDATE_COLUMN_INTERNAL_ITEM_ORDER,
        payload: { columnId, newOrder }
    });
};

export const selectItem = (itemId) => (dispatch) => {
    dispatch({
        type: SELECT_INTERNAL_ITEM,
        payload: { itemId }
    });
};

// ---------------------------InternalItem Comments--------------------------------------

export const fetchInternalItemComments = (itemId) => async (dispatch) => {
    try {
        const response = await axiosServices.get(`/api/internal-comments/task/${itemId}?isActive=true`);

        dispatch({
            type: FETCH_INTERNAL_ITEM_COMMENTS,
            payload: { internalComments: response.data.data.docs }
        });
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const addInternalItemComment = (itemId, commentData) => async (dispatch) => {
    try {
        const response = await axiosServices.post(`/api/internal-comments/${itemId}`, commentData);
        dispatch({
            type: ADD_INTERNAL_ITEM_COMMENT,
            payload: { internalComment: response.data.data.docs }
        });
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Komenti u shtua me sukses!',
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'success'
        });
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const deleteInternalItemComment = (internalCommentId) => async (dispatch) => {
    try {
        await axiosServices.patch(`/api/internal-comments/${internalCommentId}`, { isActive: false });
        dispatch({
            type: DELETE_INTERNAL_ITEM_COMMENT,
            payload: { internalCommentId }
        });
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Komenti u fshi me sukses!',
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'success'
        });
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};
