// actions.js (project actions)
import axiosServices from 'utils/axios';
import { SNACKBAR_OPEN } from 'store/actions';

// Action Types
export const ADD_INTERNAL_PROJECT = '@internalProject/ADD_INTERNAL_PROJECT';
export const EDIT_INTERNAL_PROJECT = '@internalProject/EDIT_INTERNAL_PROJECT';
export const DELETE_INTERNAL_PROJECT = '@internalProject/DELETE_INTERNAL_PROJECT';
export const FETCH_INTERNAL_PROJECTS = '@internalProject/FETCH_INTERNAL_PROJECTS';
export const FETCH_INTERNAL_PROJECT_TEMPLATES = '@internalProject/FETCH_INTERNAL_PROJECT_TEMPLATES';

const fetchProjectsSuccess = (projects) => ({
    type: FETCH_INTERNAL_PROJECTS,
    payload: { projects }
});

const addProjectSuccess = (project) => ({
    type: ADD_INTERNAL_PROJECT,
    payload: { project }
});

const editProjectSuccess = (project) => ({
    type: EDIT_INTERNAL_PROJECT,
    payload: { project }
});

const deleteProjectSuccess = (projectId) => ({
    type: DELETE_INTERNAL_PROJECT,
    payload: { projectId }
});

const fetchTemplatesSuccess = (templates) => ({
    type: FETCH_INTERNAL_PROJECT_TEMPLATES,
    payload: { templates }
});

// -------------------------------------------------------- ACTION CREATORS --------------------------------------------------------

export const fetchInternalProjects = () => async (dispatch) => {
    try {
        const response = await axiosServices.get('/api/internal-projects');

        dispatch(fetchProjectsSuccess(response.data.data.docs));
    } catch (error) {
        console.error('Error fetching internal-projects:', error);
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const addInternalProject = (projectData) => async (dispatch) => {
    try {
        const response = await axiosServices.post('/api/internal-projects/create', projectData);

        dispatch(addProjectSuccess(response.data));
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Projekti u shtua me sukses!',
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'success'
        });
    } catch (error) {
        console.error('Error adding project:', error);
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const editInternalProject = (id, projectData) => async (dispatch) => {
    try {
        const response = await axiosServices.patch(`/api/internal-projects/${id}`, projectData);

        dispatch(editProjectSuccess(response.data));
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Projekti u përditesua me sukses!',
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'success'
        });
    } catch (error) {
        console.error('Error editing project:', error);
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const deleteInternalProject = (id) => async (dispatch) => {
    try {
        await axiosServices.patch(`/api/internal-projects/${id}`, { isActive: false });

        dispatch(deleteProjectSuccess(id));
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Projekti u fshi me sukses!',
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'success'
        });
    } catch (error) {
        console.error('Error deleting project:', error);
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const fetchInternalProjectTemplates = () => async (dispatch) => {
    try {
        const response = await axiosServices.get('/api/internal-projects/templates');
        dispatch(fetchTemplatesSuccess(response.data));
    } catch (error) {
        console.error('Error fetching internal project templates:', error);
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};
