// actions.js (service actions)
import axiosServices from 'utils/axios';
import { SNACKBAR_OPEN } from 'store/actions';

// Action Types
export const ADD_SERVICE = '@service/ADD_SERVICE';
export const EDIT_SERVICE = '@service/EDIT_SERVICE';
export const DELETE_SERVICE = '@service/DELETE_SERVICE';
export const FETCH_SERVICES = '@service/FETCH_SERVICE';
export const FETCH_ONE_SERVICE = '@service/FETCH_ONE_SERVICE';

const fetchServicesSuccess = (services) => ({
    type: FETCH_SERVICES,
    payload: { services }
});

const fetchOneServiceSuccess = (service) => ({
    type: FETCH_ONE_SERVICE,
    payload: { service }
});

const addServiceSuccess = (service) => ({
    type: ADD_SERVICE,
    payload: { service }
});

const editServiceSuccess = (service) => ({
    type: EDIT_SERVICE,
    payload: { service }
});

const deleteServiceSuccess = (serviceId) => ({
    type: DELETE_SERVICE,
    payload: { serviceId }
});

// -------------------------------------------------------- ACTION CREATORS --------------------------------------------------------

export const fetchServices = () => async (dispatch) => {
    try {
        const response = await axiosServices.get('/api/services');
        dispatch(fetchServicesSuccess(response.data.data.docs));
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const fetchProjectServices = (projectId) => async (dispatch) => {
    try {
        const response = await axiosServices.get(`/api/services?projectId=${projectId}`, {
            params: {
                isActive: true
            }
        });

        dispatch(fetchServicesSuccess(response.data.data.docs));
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const fetchClientServices = (clientId) => async (dispatch) => {
    try {
        const response = await axiosServices.get(`/api/services/clients/${clientId}`);

        dispatch(fetchServicesSuccess(response.data.data));
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const fetchOneService = (serviceId) => async (dispatch) => {
    try {
        const response = await axiosServices.get(`/api/services/${serviceId}`);
        dispatch(fetchOneServiceSuccess(response.data.data));
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const addServices = (serviceData) => async (dispatch) => {
    try {
        const response = await axiosServices.post('/api/services/create', serviceData);

        dispatch(addServiceSuccess(response.data));
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const editServices = (id, serviceData) => async (dispatch) => {
    try {
        const response = await axiosServices.patch(`/api/services/${id}`, serviceData);

        dispatch(editServiceSuccess(response.data));
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const deleteServices = (id) => async (dispatch) => {
    try {
        await axiosServices.patch(`/api/services/${id}`, { isActive: false });

        dispatch(deleteServiceSuccess(id));
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};
