// third-party

// assets
import { IconUserCheck, IconUsers, IconUser } from '@tabler/icons';

// constant
const icons = {
    IconUserCheck,
    IconUsers,
    IconUser
};

// ==============================|| USERS ||============================== //

const users = {
    id: 'users',
    title: 'Përdoruesit',
    type: 'group',
    children: [
        {
            id: 'subcontractorsUsers',
            title: 'Nënkontraktorët',
            type: 'item',
            url: '/subcontractors',
            icon: icons.IconUserCheck,
            breadcrumbs: false
        },
        {
            id: 'employeesUsers',
            title: 'Punëtorët',
            type: 'item',
            url: '/employees',
            icon: icons.IconUser,
            breadcrumbs: false
        },
        {
            id: 'clientsUsers',
            title: 'Klientët',
            type: 'item',
            url: '/clients',
            icon: icons.IconUsers
        }
    ]
};

export default users;
