import axios from 'axios';
import config from 'config';
import jwtDecode from 'jwt-decode';

const axiosServices = axios.create({
    baseURL: config.API_URL
});

const refreshAccessToken = async (config) => {
    try {
        const oldRefreshToken = window.localStorage.getItem('refreshToken');
        if (!oldRefreshToken) {
            throw new Error('Refresh token not available');
        }

        const response = await axiosServices.get('/api/auth', {
            params: { refreshToken: oldRefreshToken }
        });

        const { accessToken, refreshToken } = response.data.data;

        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);

        config.headers.Authorization = `Bearer ${accessToken}`;
    } catch (error) {
        console.error('Error refreshing access token:', error);
    }
};

axiosServices.interceptors.request.use(async (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        const decodedToken = jwtDecode(accessToken);
        if (decodedToken.exp * 1000 < Date.now() && !config.API_URL.includes('/api/auth')) {
            await refreshAccessToken(config);
        } else {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
    }
    return config;
});

axiosServices.interceptors.response.use(
    (response) => response,
    (error) => {
        console.error('Axios Interceptor Error:', error);
        console.log('Response:', error.response);
        return Promise.reject((error.response && error.response.data) || 'Wrong Services');
    }
);

export default axiosServices;
