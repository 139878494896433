// third-party

// assets
import { IconLayoutKanban, IconClipboardData, IconBrandTrello } from '@tabler/icons';

// constant
const icons = {
    IconClipboardData,
    IconLayoutKanban,
    IconBrandTrello
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Ballina',
    type: 'group',
    children: [
        {
            id: 'projects',
            title: 'Projektet',
            type: 'item',
            url: '/projects',
            icon: icons.IconLayoutKanban,
            breadcrumbs: false
        },
        {
            id: 'internalProjects',
            title: 'Projektet e brendshme',
            type: 'item',
            url: '/internalProjects',
            icon: icons.IconBrandTrello,
            breadcrumbs: false
        },
        {
            id: 'services',
            title: 'Serviset',
            type: 'item',
            url: '/allServices',
            icon: icons.IconClipboardData
        }
    ]
};

export default dashboard;
