import { FETCH_CLIENTS, ADD_CLIENT, EDIT_CLIENT, DELETE_CLIENT, FETCH_CLIENT_QR_CODE } from './clientActions';

const initialState = {
    clients: [],
    qrCode: null
};

const clientReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CLIENTS:
            return {
                ...state,
                clients: action.payload.clients.docs
            };

        case ADD_CLIENT:
            return {
                ...state,
                clients: [...state.clients, action.payload.client]
            };
        case EDIT_CLIENT:
            return {
                ...state,
                clients: Array.isArray(state.clients)
                    ? state.clients.map((client) => (client.id === action.payload.client.id ? action.payload.client : client))
                    : state.clients
            };
        case DELETE_CLIENT:
            return {
                ...state,
                clients: state.clients.filter((client) => client.id !== action.payload.clientId)
            };
        case FETCH_CLIENT_QR_CODE:
            return {
                ...state,
                qrCode: action.payload.qrCode
            };
        default:
            return state;
    }
};

export default clientReducer;
