import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from 'config';
import Logo from 'ui-component/Logo';
import { useSelector } from 'react-redux';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    const meData = useSelector((state) => state.authentication.meData);
    const isAdmin = meData && meData.role === 'ADMIN';
    const isEmployee = meData && meData.role === 'EMPLOYEE';
    const isSubcontractor = meData && meData.role === 'SUBCONTRACTOR';
    const isAuthorizedUser = isAdmin || isEmployee || isSubcontractor;

    return (
        <ButtonBase
            disableRipple={!isAuthorizedUser}
            component={isAuthorizedUser ? Link : 'div'}
            to={isAuthorizedUser ? config.defaultPath : null}
        >
            <Logo />
        </ButtonBase>
    );
};

export default LogoSection;
