/* eslint-disable no-case-declarations */
import {
    UPDATE_COLUMN_INTERNAL_ITEM_ORDER,
    DELETE_INTERNAL_ITEM,
    SELECT_INTERNAL_ITEM,
    ADD_INTERNAL_ITEM,
    ADD_INTERNAL_ITEM_COMMENT,
    EDIT_INTERNAL_ITEM,
    FETCH_INTERNAL_ITEMS_SUCCESS,
    GET_INTERNAL_ITEMS_FETCH,
    FETCH_INTERNAL_ITEM_COMMENTS,
    DELETE_INTERNAL_ITEM_COMMENT
} from './internalKanbanActions';

const initialState = {
    internalColumns: [
        { id: 'column1', title: 'TODO', itemIds: [] },
        { id: 'column2', title: 'DONE', itemIds: [] }
    ],
    internalColumnsOrder: ['column1', 'column2'],
    internalComments: [],
    internalItems: [],
    profiles: [],
    selectedItem: false
};

const kanbanReducer = (state = initialState, action) => {
    let itemIds;

    switch (action.type) {
        case GET_INTERNAL_ITEMS_FETCH:
            return initialState;
        case FETCH_INTERNAL_ITEMS_SUCCESS:
            const { internalItems } = action.payload;
            const updatedColumnsWithItems = state.internalColumns.map((column) => {
                let updatedItemIds = column.itemIds.slice();
                internalItems.forEach((item) => {
                    if (item.status === column.title) {
                        if (!updatedItemIds.includes(item.id)) {
                            updatedItemIds.push(item.id);
                        }
                    } else {
                        updatedItemIds = updatedItemIds.filter((id) => id !== item.id);
                    }
                });
                return {
                    ...column,
                    itemIds: updatedItemIds
                };
            });
            return {
                ...state,
                internalItems,
                internalColumns: updatedColumnsWithItems
            };

        case ADD_INTERNAL_ITEM:
            const { columnId, item } = action.payload;
            const updatedColumns = state.internalColumns.map((column) => {
                if (column.id === columnId) {
                    return {
                        ...column,
                        itemIds: [...column.itemIds, item.id]
                    };
                }
                return column;
            });

            return {
                ...state,
                internalItems: [...state.internalItems, item],
                internalColumns: updatedColumns
            };

        case EDIT_INTERNAL_ITEM:
            const newColumnEditItem = state.internalColumns.map((column) => {
                if (column.id === action.payload.columnId) {
                    if (!column.itemIds.includes(action.payload.item.id)) {
                        return {
                            ...column,
                            itemIds: [...(column.itemIds || []), action.payload.item.id]
                        };
                    }
                } else if (column.id === action.payload.oldColumnId) {
                    return {
                        ...column,
                        itemIds: column.itemIds.filter((id) => id !== action.payload.item.id)
                    };
                }
                return column;
            });

            return {
                ...state,
                internalItems: state.internalItems.map((item) => (item.id === action.payload.item.id ? action.payload.item : item)),
                internalColumns: newColumnEditItem
            };

        case UPDATE_COLUMN_INTERNAL_ITEM_ORDER:
            return {
                ...state,
                internalColumns: action.payload.internalColumns
            };
        case SELECT_INTERNAL_ITEM:
            return {
                ...state,
                selectedItem: action.payload.selectedItem
            };

        case DELETE_INTERNAL_ITEM:
            const newColumn = state.internalColumns.map((column) => {
                itemIds = column.itemIds.filter((id) => id !== action.payload.itemId);
                return {
                    ...column,
                    itemIds
                };
            });

            return {
                ...state,
                internalItems: state.internalItems.filter((item) => item.id !== action.payload.itemId),
                internalColumns: newColumn
            };

        case FETCH_INTERNAL_ITEM_COMMENTS:
            const updatedItems = state.internalItems.map((item) => {
                if (action.payload.internalComments[item.id]) {
                    return {
                        ...item,
                        internalComments: action.payload.internalComments[item.id]
                    };
                }
                return item;
            });

            return {
                ...state,
                internalItems: updatedItems,
                internalComments: action.payload.comments
            };

        case ADD_INTERNAL_ITEM_COMMENT:
            const newItems = state.internalItems.map((item) => {
                if (item.id === action.payload.itemId) {
                    return {
                        ...item,
                        commentIds: [...(item.commentIds || []), action.payload.comment.id]
                    };
                }
                return item;
            });

            return {
                ...state,
                internalItems: newItems,
                internalComments: action.payload.comment
            };

        case DELETE_INTERNAL_ITEM_COMMENT:
            const updatedItemsAfterDelete = state.internalItems.map((item) => {
                const updatedComments = item.comments.filter((commentId) => commentId !== action.payload.commentId);
                return {
                    ...item,
                    comments: updatedComments
                };
            });

            return {
                ...state,
                internalItems: updatedItemsAfterDelete
            };

        default:
            return state;
    }
};

export default kanbanReducer;
