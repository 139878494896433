import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// reducer import
import customizationReducer from './customizationReducer';
import snackbarReducer from './snackbarReducer';
import cartReducer from './cartReducer';
import kanbanReducer from './kanban/kanbanReducer';
import projectReducer from './project/projectReducer';
import accountReducer from './authentication/accountReducer';
import userReducer from './users/userReducer';
import clientReducer from './clients/clientReducer';
import authenticationReducer from './authentication/authenticationReducer';
import serviceReducer from './services/servicesReducer';
import serviceTaskReducer from './service-tasks/serviceTasksReducer';
import internalProjectReducer from './internal-project/internalProjectReducer';
import internalKanbanReducer from './internal-project-kanban/internalKanbanReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    account: accountReducer,
    authentication: authenticationReducer,
    customization: customizationReducer,
    snackbar: snackbarReducer,
    cart: persistReducer(
        {
            key: 'cart',
            storage,
            keyPrefix: 'berry-'
        },
        cartReducer
    ),
    clients: clientReducer,
    users: userReducer,
    internalProjects: internalProjectReducer,
    internalKanban: internalKanbanReducer,
    projects: projectReducer,
    services: serviceReducer,
    serviceTasks: serviceTaskReducer,
    kanban: kanbanReducer
});

export default reducer;
