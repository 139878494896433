/* eslint-disable */
// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { useSelector } from 'react-redux';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const meData = useSelector((state) => state.authentication.meData);

    const isAdmin = meData && meData.role === 'ADMIN';
    const isEmployee = meData && meData.role === 'EMPLOYEE';
    const CalendarNavItem = menuItem.items.other.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return null;
        }
    });

    const dashboard = menuItem.items.dashboard;

    const users = menuItem.items.users;

    const subcontractors = menuItem.items.subcontractors;

    const employees = menuItem.items.employees;

    return (
        <>
            {isAdmin && (
                <>
                    {dashboard.map((item) => (
                        <NavGroup key={item.id} item={item} />
                    ))}
                    {users.map((item) => (
                        <NavGroup key={item.id} item={item} />
                    ))}
                    {/* {CalendarNavItem} */}
                </>
            )}

            {isEmployee && (
                <>
                    {employees.map((item) => (
                        <NavGroup key={item.id} item={item} />
                    ))}
                </>
            )}

            {!isAdmin && !isEmployee && (
                <>
                    {subcontractors.map((item) => (
                        <NavGroup key={item.id} item={item} />
                    ))}
                </>
            )}
        </>
    );
};

export default MenuList;
