import axiosServices from 'utils/axios';
import { SNACKBAR_OPEN } from 'store/actions';

export const FETCH_CLIENTS = '@subcontractor/FETCH_CLIENTS';
export const ADD_CLIENT = '@subcontractor/ADD_CLIENT';
export const EDIT_CLIENT = '@subcontractor/EDIT_CLIENT';
export const DELETE_CLIENT = '@subcontractor/DELETE_CLIENT';
export const FETCH_CLIENT_QR_CODE = '@subcontractor/FETCH_CLIENT_QR_CODE';

const fetchClientsSuccess = (clients) => ({
    type: FETCH_CLIENTS,
    payload: { clients }
});

const addClientSuccess = (client) => ({
    type: ADD_CLIENT,
    payload: { client }
});

const editClientSuccess = (client) => ({
    type: EDIT_CLIENT,
    payload: { client }
});

const deleteClientSuccess = (clientId) => ({
    type: DELETE_CLIENT,
    payload: { clientId }
});
export const fetchClientQRCodeSuccess = (clientId) => ({
    type: FETCH_CLIENT_QR_CODE,
    payload: { clientId }
});

// -------------------------------------------------------- ACTION CREATORS --------------------------------------------------------

export const fetchClients = () => async (dispatch) => {
    try {
        const response = await axiosServices.get('/api/clients', {
            params: {
                isActive: true
            }
        });

        dispatch(fetchClientsSuccess(response.data.data));
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const addClient = (clientData) => async (dispatch) => {
    try {
        const response = await axiosServices.post('/api/clients/create', clientData);
        dispatch(addClientSuccess(response.data));
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Klienti u shtua me sukses!',
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'success'
        });
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const editClient = (id, editedFields) => async (dispatch) => {
    try {
        const response = await axiosServices.patch(`/api/clients/${id}`, editedFields);
        dispatch(editClientSuccess(response.data));
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Klienti u përditësua me sukses!',
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'success'
        });
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const deleteClient = (id) => async (dispatch) => {
    try {
        await axiosServices.patch(`/api/clients/${id}`, { isActive: false });
        dispatch(deleteClientSuccess(id));
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Klienti u fshi me sukses!',
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'success'
        });
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};
