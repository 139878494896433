// actions.js (project actions)
import axiosServices from 'utils/axios';
import { SNACKBAR_OPEN } from 'store/actions';

// Actions

export const ADD_SERVICE_ITEM = '@serviceTasks/ADD_ITEM';
export const EDIT_SERVICE_ITEM = '@serviceTasks/EDIT_ITEM';
export const DELETE_SERVICE_ITEM = '@serviceTasks/DELETE_ITEM';
export const UPDATE_COLUMN_ITEM_ORDER = '@serviceTasks/UPDATE_COLUMN_ITEM_ORDER';
export const SELECT_SERVICE_ITEM = '@serviceTasks/SELECT_ITEM';
export const FETCH_SERVICE_ITEM_COMMENTS = '@serviceTasks/FETCH_ITEM_COMMENTS';
export const EDIT_SERVICE_ITEM_COMMENT = '@serviceTasks/EDIT_ITEM_COMMENT';
export const ADD_SERVICE_ITEM_COMMENT = '@serviceTasks/ADD_ITEM_COMMENT';
export const FETCH_SERVICE_ITEMS = '@serviceTasks/FETCH_ITEMS';
export const DELETE_SERVICE_ITEM_COMMENT = '@serviceTasks/DELETE_ITEM_COMMENT';

// Action Creators

// ---------------------------Items--------------------------------------

export const fetchServiceItems = (serviceId) => async (dispatch) => {
    try {
        const response = await axiosServices.get(`/api/service-tasks/service/${serviceId}`);

        dispatch({
            type: FETCH_SERVICE_ITEMS,
            payload: { items: response.data.data.docs }
        });
    } catch (error) {
        console.error('Error fetching Service tasks:', error);
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const addServiceItem = (itemData) => async (dispatch) => {
    try {
        const response = await axiosServices.post('/api/service-tasks/create', itemData);

        const newItem = response.data.data;

        dispatch({
            type: ADD_SERVICE_ITEM,
            payload: { item: newItem }
        });
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Tasku u shtua me sukses!',
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'success'
        });
    } catch (error) {
        console.error('Error adding Service task:', error);
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const editServiceItem = (itemId, itemData) => async (dispatch) => {
    try {
        const response = await axiosServices.patch(`/api/service-tasks/${itemId}`, itemData);

        dispatch({
            type: EDIT_SERVICE_ITEM,
            payload: { item: response.data.data }
        });
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Tasku u përditesua me sukses!',
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'success'
        });
    } catch (error) {
        console.error('Error editing Service task:', error);
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const editItemStatusSubcontractor = (itemId, status) => async (dispatch) => {
    try {
        const response = await axiosServices.patch(`/api/service-tasks/${itemId}/status`, { status });

        dispatch({
            type: EDIT_SERVICE_ITEM,
            payload: { item: response.data }
        });
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Tasku u përditesua me sukses!',
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'success'
        });
    } catch (error) {
        console.error('Error editing item status:', error);
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const editItemStatus = (itemId, currentStatus) => async (dispatch) => {
    try {
        const newStatus = currentStatus === 'TODO' ? 'DONE' : 'TODO';

        const response = await axiosServices.patch(`/api/service-tasks/${itemId}/status`, { status: newStatus });

        dispatch({
            type: EDIT_SERVICE_ITEM,
            payload: { item: response.data }
        });
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Tasku u përditesua me sukses!',
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'success'
        });
    } catch (error) {
        console.error('Error editing item status:', error);
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const deleteServiceItem = (itemId) => async (dispatch) => {
    try {
        await axiosServices.delete(`/api/service-tasks/${itemId}`);

        dispatch({
            type: DELETE_SERVICE_ITEM,
            payload: { itemId }
        });
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Tasku u fshi me sukses!',
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'success'
        });
    } catch (error) {
        console.error('Error deleting task:', error);
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const updateColumnItemOrder = (columnId, newOrder) => (dispatch) => {
    dispatch({
        type: UPDATE_COLUMN_ITEM_ORDER,
        payload: { columnId, newOrder }
    });
};

export const selectItem = (itemId) => (dispatch) => {
    dispatch({
        type: SELECT_SERVICE_ITEM,
        payload: { itemId }
    });
};

// ---------------------------Item Comments--------------------------------------

export const fetchServiceItemComments = (itemId) => async (dispatch) => {
    try {
        const response = await axiosServices.get(`/api/service-comments/task/${itemId}?isActive=true`);

        dispatch({
            type: FETCH_SERVICE_ITEM_COMMENTS,
            payload: { comments: response.data.data.docs }
        });
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const addServiceItemComment = (itemId, commentData) => async (dispatch) => {
    try {
        const response = await axiosServices.post(`/api/service-comments/${itemId}`, commentData);
        dispatch({
            type: ADD_SERVICE_ITEM_COMMENT,
            payload: { comment: response.data.data.docs }
        });
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Komenti u shtua me sukses!',
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'success'
        });
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const deleteServiceItemComment = (commentId) => async (dispatch) => {
    try {
        await axiosServices.patch(`/api/service-comments/${commentId}`, { isActive: false });
        dispatch({
            type: DELETE_SERVICE_ITEM_COMMENT,
            payload: { commentId }
        });
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Komenti u fshi me sukses!',
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'success'
        });
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};
