// authenticationReducer.js

import { FETCH_USER_DATA, REFRESH_TOKEN, FETCH_ME_DATA, AUTO_LOGIN } from './authenticationActions';

const initialState = {
    userData: null,
    accessToken: null,
    meData: null,
    autoLogin: null,
    isLoggedIn: false
};

const authenticationReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USER_DATA:
            return {
                ...state,
                userData: action.payload.userData
            };
        case REFRESH_TOKEN:
            return {
                ...state,
                accessToken: action.payload.accessToken
            };
        case FETCH_ME_DATA:
            return {
                ...state,
                meData: action.payload.meData
            };
        case AUTO_LOGIN:
            return {
                ...state,
                autoLogin: action.payload.refreshToken,
                isLoggedIn: true
            };
        default:
            return state;
    }
};

export default authenticationReducer;
