import { FETCH_SERVICES, ADD_SERVICE, EDIT_SERVICE, DELETE_SERVICE, FETCH_ONE_SERVICE } from './servicesActions';

const initialState = {
    services: [],
    qrCode: null
};

const serviceReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SERVICES:
            return {
                ...state,
                services: action.payload.services
            };
        case FETCH_ONE_SERVICE:
            return {
                ...state,
                service: action.payload.service
            };
        case ADD_SERVICE:
            return {
                ...state,
                services: [...state.services, action.payload.service]
            };
        case EDIT_SERVICE:
            return {
                ...state,
                services: state.services.map((service) => (service.id === action.payload.service.id ? action.payload.service : service))
            };
        case DELETE_SERVICE:
            return {
                ...state,
                services: state.services.filter((service) => service.id !== action.payload.serviceId)
            };
        default:
            return state;
    }
};

export default serviceReducer;
