import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, useMediaQuery } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import config from 'config';
// project imports
import LogoSection from '../LogoSection';
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';

// assets
import { IconMenu2 } from '@tabler/icons';
import { useSelector } from 'react-redux';
import { Home } from '@mui/icons-material';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const meData = useSelector((state) => state.authentication.meData);
    const location = useLocation();

    const isAdmin = meData && meData.role === 'ADMIN';
    const isEmployee = meData && meData.role === 'EMPLOYEE';
    const isSubcontractor = meData && meData.role === 'SUBCONTRACTOR';
    const isAuthorizedUser = isAdmin || isEmployee;

    const isProjectsPath = location.pathname === '/projects';

    return (
        <>
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                {isAuthorizedUser && !isMobile && (
                    <Box component="span" sx={{ flexGrow: 1 }}>
                        <LogoSection />
                    </Box>
                )}

                {isAuthorizedUser && (
                    <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                transition: 'all .2s ease-in-out',
                                background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                                color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                                '&:hover': {
                                    background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                                    color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
                                }
                            }}
                            onClick={handleLeftDrawerToggle}
                            color="inherit"
                        >
                            <IconMenu2 stroke={1.5} size="1.3rem" />
                        </Avatar>
                    </ButtonBase>
                )}

                {isSubcontractor && !isProjectsPath && (
                    <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                        <Link to={config.defaultPath} style={{ textDecoration: 'none' }}>
                            <Avatar
                                variant="rounded"
                                sx={{
                                    ...theme.typography.commonAvatar,
                                    ...theme.typography.mediumAvatar,
                                    transition: 'all .2s ease-in-out',
                                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                                    color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                                    '&:hover': {
                                        background:
                                            theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                                        color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
                                    }
                                }}
                                color="inherit"
                            >
                                <Home stroke={1.5} size="1.3rem" />
                            </Avatar>
                        </Link>
                    </ButtonBase>
                )}
            </Box>

            <Box sx={{ flexGrow: 1 }} />
            {!isMobile && <Box sx={{ flexGrow: 1 }} />}
            {isMobile && (
                <Box
                    component="span"
                    sx={{
                        display: {
                            xs: isAuthorizedUser || (isSubcontractor && !isProjectsPath) ? 'none' : 'block',
                            md: 'block'
                        },
                        flexGrow: 1
                    }}
                >
                    <LogoSection />
                </Box>
            )}

            {(isAdmin || isEmployee) && <ProfileSection />}
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box>
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
