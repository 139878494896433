import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import config from 'config';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { autoLogin } from 'store/authentication/authenticationActions';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }) => {
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const currentURL = window.location.href;

    const url = new URL(currentURL);

    const refreshToken = url.searchParams.get('refreshToken');

    useEffect(() => {
        if (window.location.href.includes('qrCodeAuth=true')) {
            dispatch(autoLogin(refreshToken));
        }
    }, [dispatch, refreshToken]);

    useEffect(() => {
        if (isLoggedIn) {
            navigate(config.defaultPath, { replace: true });
        }
    }, [isLoggedIn, navigate]);

    return children;
};

GuestGuard.propTypes = {
    children: PropTypes.node
};

export default GuestGuard;
