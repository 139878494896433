import axiosServices from 'utils/axios';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

// authenticationActions.js

// Action Types
export const FETCH_USER_DATA = '@authentication/FETCH_USER_DATA';
export const REFRESH_TOKEN = '@authentication/REFRESH_TOKEN';
export const FETCH_ME_DATA = '@authentication/FETCH_ME_DATA';
export const AUTO_LOGIN = '@authentication/AUTO_LOGIN';

const fetchUserDataSuccess = (userData) => ({
    type: FETCH_USER_DATA,
    payload: { userData }
});

const refreshTokenSuccess = (accessToken) => ({
    type: REFRESH_TOKEN,
    payload: { accessToken }
});
const fetchMeDataSuccess = (meData) => ({
    type: FETCH_ME_DATA,
    payload: { meData }
});
const autoLoginSuccess = (refreshToken) => ({
    type: AUTO_LOGIN,
    payload: { refreshToken }
});
//-----------------------------------------------------------------------------------------------------

export const fetchUserData = () => async (dispatch) => {
    try {
        const response = await axiosServices.get('/api/user');

        dispatch(fetchUserDataSuccess(response.data));
    } catch (error) {
        console.error('Error fetching user data:', error);
    }
};

export const refreshToken = () => async (dispatch) => {
    try {
        const refreshToken = window.localStorage.getItem('refreshToken');
        if (!refreshToken) {
            throw new Error('Refresh token not available');
        }

        const response = await axiosServices.post('/api/auth/refresh', { refreshToken });
        const { accessToken } = response.data;

        localStorage.setItem('accessToken', accessToken);

        dispatch(refreshTokenSuccess(accessToken));
    } catch (error) {
        console.error('Error refreshing access token:', error);
    }
};

export const fetchMeData = () => async (dispatch) => {
    try {
        const response = await axiosServices.get('/api/me');

        dispatch(fetchMeDataSuccess(response.data.data));
    } catch (error) {
        console.error('Error fetching "me" data:', error);
    }
};

export const autoLogin = (refreshToken) => async (dispatch) => {
    try {
        const response = await axiosServices.get(`/api/auth?refreshToken=${refreshToken}`);

        const accessToken = response.data.data.accessToken;
        const rToken = response.data.data.refreshToken;
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', rToken);
        localStorage.setItem('test', true);
        axiosServices.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        dispatch(autoLoginSuccess(response.data.data));
        window.location.href = '/projects';
    } catch (error) {
        console.error('Error auto login:', error);
    }
};
