/* eslint-disable no-case-declarations */
import { UPDATE_COLUMN_ITEM_ORDER, DELETE_ITEM, SELECT_ITEM, ADD_ITEM, ADD_ITEM_COMMENT, EDIT_ITEM } from '../actions';

import { GET_ITEMS_FETCH, GET_ITEMS_SUCCESS, FETCH_ITEM_COMMENTS, DELETE_ITEM_COMMENT } from './kanbanActions';

const initialState = {
    columns: [
        { id: 'column1', title: 'TODO', itemIds: [] },
        { id: 'column2', title: 'DONE', itemIds: [] }
    ],
    columnsOrder: ['column1', 'column2'],
    comments: [],
    items: [],
    profiles: [],
    selectedItem: false
};

const kanbanReducer = (state = initialState, action) => {
    let itemIds;

    switch (action.type) {
        case GET_ITEMS_FETCH:
            return initialState;
        case GET_ITEMS_SUCCESS:
            const { items } = action.payload;
            const updatedColumnsWithItems = state.columns.map((column) => {
                let updatedItemIds = column.itemIds.slice();
                items.forEach((item) => {
                    if (item.status === column.title) {
                        if (!updatedItemIds.includes(item.id)) {
                            updatedItemIds.push(item.id);
                        }
                    } else {
                        updatedItemIds = updatedItemIds.filter((id) => id !== item.id);
                    }
                });
                return {
                    ...column,
                    itemIds: updatedItemIds
                };
            });
            return {
                ...state,
                items,
                columns: updatedColumnsWithItems
            };

        case ADD_ITEM:
            const { columnId, item } = action.payload;
            const updatedColumns = state.columns.map((column) => {
                if (column.id === columnId) {
                    return {
                        ...column,
                        itemIds: [...column.itemIds, item.id]
                    };
                }
                return column;
            });

            return {
                ...state,
                items: [...state.items, item],
                columns: updatedColumns
            };

        case EDIT_ITEM:
            const newColumnEditItem = state.columns.map((column) => {
                if (column.id === action.payload.columnId) {
                    if (!column.itemIds.includes(action.payload.item.id)) {
                        return {
                            ...column,
                            itemIds: [...(column.itemIds || []), action.payload.item.id]
                        };
                    }
                } else if (column.id === action.payload.oldColumnId) {
                    return {
                        ...column,
                        itemIds: column.itemIds.filter((id) => id !== action.payload.item.id)
                    };
                }
                return column;
            });

            return {
                ...state,
                items: state.items.map((item) => (item.id === action.payload.item.id ? action.payload.item : item)),
                columns: newColumnEditItem
            };

        case UPDATE_COLUMN_ITEM_ORDER:
            return {
                ...state,
                columns: action.payload.columns
            };
        case SELECT_ITEM:
            return {
                ...state,
                selectedItem: action.payload.selectedItem
            };

        case DELETE_ITEM:
            const newColumn = state.columns.map((column) => {
                itemIds = column.itemIds.filter((id) => id !== action.payload.itemId);
                return {
                    ...column,
                    itemIds
                };
            });

            return {
                ...state,
                items: state.items.filter((item) => item.id !== action.payload.itemId),
                columns: newColumn
            };

        case FETCH_ITEM_COMMENTS:
            const updatedItems = state.items.map((item) => {
                if (action.payload.comments[item.id]) {
                    return {
                        ...item,
                        comments: action.payload.comments[item.id]
                    };
                }
                return item;
            });

            return {
                ...state,
                items: updatedItems,
                comments: action.payload.comments
            };

        case ADD_ITEM_COMMENT:
            const newItems = state.items.map((item) => {
                if (item.id === action.payload.itemId) {
                    return {
                        ...item,
                        commentIds: [...(item.commentIds || []), action.payload.comment.id]
                    };
                }
                return item;
            });

            return {
                ...state,
                items: newItems,
                comments: [...state.comments, action.payload.comment]
            };

        case DELETE_ITEM_COMMENT:
            const updatedItemsAfterDelete = state.items.map((item) => {
                const updatedComments = item.comments.filter((commentId) => commentId !== action.payload.commentId);
                return {
                    ...item,
                    comments: updatedComments
                };
            });

            return {
                ...state,
                items: updatedItemsAfterDelete
            };

        default:
            return state;
    }
};

export default kanbanReducer;
