import { createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import { thunk } from 'redux-thunk';
import reducer from './reducer';

// ==============================|| REDUX - MAIN STORE ||============================== //

const middleware = [thunk];

const store = createStore(reducer, applyMiddleware(...middleware));
const persister = persistStore(store);

export { store, persister };
