// actions.js (project actions)
import axiosServices from 'utils/axios';
import { SNACKBAR_OPEN } from 'store/actions';

// Action Types
export const ADD_PROJECT = '@project/ADD_PROJECT';
export const EDIT_PROJECT = '@project/EDIT_PROJECT';
export const DELETE_PROJECT = '@project/DELETE_PROJECT';
export const FETCH_PROJECTS = '@project/FETCH_PROJECTS';

const fetchProjectsSuccess = (projects) => ({
    type: FETCH_PROJECTS,
    payload: { projects }
});

const addProjectSuccess = (project) => ({
    type: ADD_PROJECT,
    payload: { project }
});

const editProjectSuccess = (project) => ({
    type: EDIT_PROJECT,
    payload: { project }
});

const deleteProjectSuccess = (projectId) => ({
    type: DELETE_PROJECT,
    payload: { projectId }
});

// -------------------------------------------------------- ACTION CREATORS --------------------------------------------------------

export const fetchProjects = () => async (dispatch) => {
    try {
        const response = await axiosServices.get('/api/projects');
        dispatch(fetchProjectsSuccess(response.data.data.docs));
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const addProject = (projectData) => async (dispatch) => {
    try {
        const response = await axiosServices.post('/api/projects', projectData);

        dispatch(addProjectSuccess(response.data));
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Projekti u shtua me sukses!',
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'success'
        });
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const editProject = (id, projectData) => async (dispatch) => {
    try {
        const response = await axiosServices.patch(`/api/projects/${id}`, projectData);

        dispatch(editProjectSuccess(response.data));
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Projekti u përditesua me sukses!',
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'success'
        });
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const deleteProject = (id) => async (dispatch) => {
    try {
        await axiosServices.patch(`/api/projects/${id}`, { isActive: false });

        dispatch(deleteProjectSuccess(id));
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Projekti u fshi me sukses!',
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'success'
        });
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};
