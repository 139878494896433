/* eslint-disable no-case-declarations */
// import { UPDATE_COLUMN_ITEM_ORDER, DELETE_ITEM, SELECT_ITEM, ADD_ITEM, ADD_ITEM_COMMENT, EDIT_ITEM } from '../actions';

import {
    UPDATE_COLUMN_ITEM_ORDER,
    DELETE_SERVICE_ITEM,
    SELECT_SERVICE_ITEM,
    ADD_SERVICE_ITEM,
    ADD_SERVICE_ITEM_COMMENT,
    EDIT_SERVICE_ITEM,
    FETCH_SERVICE_ITEMS,
    FETCH_SERVICE_ITEM_COMMENTS,
    DELETE_SERVICE_ITEM_COMMENT
} from './serviceTasksActions';

const initialState = {
    serviceColumns: [
        { id: 'column1', title: 'TODO', itemIds: [] },
        { id: 'column2', title: 'DONE', itemIds: [] }
    ],
    serviceColumnsOrder: ['column1', 'column2'],
    serviceComments: [],
    serviceItems: [],
    profiles: [],
    selectedItem: false
};

const kanbanReducer = (state = initialState, action) => {
    let itemIds;

    switch (action.type) {
        case FETCH_SERVICE_ITEMS:
            const { items } = action.payload;
            const updatedServiceColumnsWithItems = state.serviceColumns.map((column) => {
                let updatedItemIds = column.itemIds.slice();
                items.forEach((item) => {
                    if (item.status === column.title) {
                        if (!updatedItemIds.includes(item.id)) {
                            updatedItemIds.push(item.id);
                        }
                    } else {
                        updatedItemIds = updatedItemIds.filter((id) => id !== item.id);
                    }
                });
                return {
                    ...column,
                    itemIds: updatedItemIds
                };
            });
            return {
                ...state,
                serviceItems: items,
                serviceColumns: updatedServiceColumnsWithItems
            };

        case ADD_SERVICE_ITEM:
            const { columnId, item } = action.payload;
            const updatedServiceColumns = state.serviceColumns.map((column) => {
                if (column.id === columnId) {
                    return {
                        ...column,
                        itemIds: [...column.itemIds, item.id]
                    };
                }
                return column;
            });

            return {
                ...state,
                serviceItems: [...state.serviceItems, item],
                serviceColumns: updatedServiceColumns
            };

        case EDIT_SERVICE_ITEM:
            const newColumnEditItem = state.serviceColumns.map((column) => {
                if (column.id === action.payload.columnId) {
                    if (!column.itemIds.includes(action.payload.item.id)) {
                        return {
                            ...column,
                            itemIds: [...(column.itemIds || []), action.payload.item.id]
                        };
                    }
                } else if (column.id === action.payload.oldColumnId) {
                    return {
                        ...column,
                        itemIds: column.itemIds.filter((id) => id !== action.payload.item.id)
                    };
                }
                return column;
            });

            return {
                ...state,
                serviceItems: state.serviceItems.map((item) => (item.id === action.payload.item.id ? action.payload.item : item)),
                serviceColumns: newColumnEditItem
            };

        case UPDATE_COLUMN_ITEM_ORDER:
            return {
                ...state,
                serviceColumns: action.payload.columns
            };
        case SELECT_SERVICE_ITEM:
            return {
                ...state,
                selectedItem: action.payload.selectedItem
            };

        case DELETE_SERVICE_ITEM:
            const newServiceColumns = state.serviceColumns.map((column) => {
                itemIds = column.itemIds.filter((id) => id !== action.payload.itemId);
                return {
                    ...column,
                    itemIds
                };
            });

            return {
                ...state,
                serviceItems: state.serviceItems.filter((item) => item.id !== action.payload.itemId),
                serviceColumns: newServiceColumns
            };

        case FETCH_SERVICE_ITEM_COMMENTS:
            const updatedServiceItems = state.serviceItems.map((item) => {
                if (action.payload.comments[item.id]) {
                    return {
                        ...item,
                        serviceComments: action.payload.comments[item.id]
                    };
                }
                return item;
            });

            return {
                ...state,
                serviceItems: updatedServiceItems,
                serviceComments: action.payload.comments
            };

        case ADD_SERVICE_ITEM_COMMENT:
            const newServiceItems = state.serviceItems.map((item) => {
                if (item.id === action.payload.itemId) {
                    return {
                        ...item,
                        commentIds: [...(item.commentIds || []), action.payload.comment.id]
                    };
                }
                return item;
            });

            return {
                ...state,
                serviceItems: newServiceItems,
                serviceComments: [...state.serviceComments, action.payload.comment]
            };

        case DELETE_SERVICE_ITEM_COMMENT:
            const updatedServiceItemsAfterDelete = state.serviceItems.map((item) => {
                const updatedComments = item.comments.filter((commentId) => commentId !== action.payload.commentId);
                return {
                    ...item,
                    serviceComments: updatedComments
                };
            });

            return {
                ...state,
                serviceItems: updatedServiceItemsAfterDelete
            };

        default:
            return state;
    }
};

export default kanbanReducer;
