import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// sample page routing
const Projects = Loadable(lazy(() => import('views/projects')));
const InternalProjects = Loadable(lazy(() => import('views/internal-projects')));
const ProjectsTasks = Loadable(lazy(() => import('views/projects/kanban')));
const InternalProjectsTasks = Loadable(lazy(() => import('views/internal-projects/internal-kanban')));
const AppCalendar = Loadable(lazy(() => import('views/calendar')));
const AppSubcontractors = Loadable(lazy(() => import('views/subcontractors')));
const AppEmployees = Loadable(lazy(() => import('views/employees')));
const AppClients = Loadable(lazy(() => import('views/clients')));
const AllServices = Loadable(lazy(() => import('views/services')));
const ServiceDetails = Loadable(lazy(() => import('views/services/serviceDetails')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/projects',
            element: <Projects />
        },
        {
            path: '/projects/:id/:title',
            element: <ProjectsTasks />
        },
        {
            path: '/internalProjects',
            element: <InternalProjects />
        },
        {
            path: '/internalProjects/:id/:title',
            element: <InternalProjectsTasks />
        },
        {
            path: '/calendar',
            element: <AppCalendar />
        },
        {
            path: '/subcontractors',
            element: <AppSubcontractors />
        },
        {
            path: '/employees',
            element: <AppEmployees />
        },
        {
            path: '/clients',
            element: <AppClients />
        },
        {
            path: '/allServices',
            element: <AllServices />
        },
        {
            path: '/allServices/:id',
            element: <AllServices />
        },
        {
            path: '/serviceDetails',
            element: <ServiceDetails />
        },
        {
            path: '/serviceDetails/:id',
            element: <ServiceDetails />
        }
    ]
};

export default MainRoutes;
