// third-party

import { IconUsers, IconUserSearch } from '@tabler/icons';

// constant
const icons = {
    IconUsers,
    IconUserSearch
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const clients = {
    id: 'clients',
    title: '',
    type: 'group',
    children: [
        {
            id: 'clients',
            title: 'Klientët',
            type: 'item',
            url: '/clients',
            icon: icons.IconUsers
        },
        {
            id: 'clienthistory',
            title: 'Historiku i Klientit',
            type: 'item',
            url: '/clientHistory',
            icon: icons.IconUserSearch
        }
    ]
};

export default clients;
