// third-party

// assets
import { IconLayoutKanban, IconClipboardData, IconBrandTrello } from '@tabler/icons';

// constant
const icons = {
    IconClipboardData,
    IconLayoutKanban,
    IconBrandTrello
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const employees = {
    id: 'employees',
    title: 'Ballina',
    type: 'group',
    children: [
        {
            id: 'projectsEmployees',
            title: 'Projektet',
            type: 'item',
            url: '/projects',
            icon: icons.IconLayoutKanban,
            breadcrumbs: false
        },
        {
            id: 'internalProjectsEmployees',
            title: 'Projektet e brendshme',
            type: 'item',
            url: '/internalProjects',
            icon: icons.IconBrandTrello,
            breadcrumbs: false
        }
    ]
};

export default employees;
