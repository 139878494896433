import { ADD_PROJECT, EDIT_PROJECT, DELETE_PROJECT, FETCH_PROJECTS } from './projectActions';

const initialState = {
    projects: []
};

const projectReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PROJECTS: {
            const newStateAdd = {
                projects: action.payload.projects
            };

            return newStateAdd;
        }
        case ADD_PROJECT: {
            const newStateAdd = {
                ...state,
                projects: [...state.projects, action.payload.projects]
            };

            return newStateAdd;
        }

        case EDIT_PROJECT: {
            const updatedProjects = state.projects.map((project) =>
                project.id === action.payload.project.id ? action.payload.project : project
            );

            const newStateEdit = {
                ...state,
                projects: updatedProjects
            };

            return newStateEdit;
        }

        case DELETE_PROJECT: {
            const newStateDelete = {
                ...state,
                projects: state.projects.filter((project) => project.id !== action.payload.projectId)
            };

            return newStateDelete;
        }

        default:
            return state;
    }
};

export default projectReducer;
