import axiosServices from 'utils/axios';
import { SNACKBAR_OPEN } from 'store/actions';

export const FETCH_USERS = '@user/FETCH_USERS';
export const ADD_USER = '@user/ADD_USER';
export const EDIT_USER = '@user/EDIT_USER';
export const DELETE_USER = '@user/DELETE_USERUSER';
export const FETCH_USER_QR_CODE = '@user/FETCH_USER_QR_CODE';

const fetchUsersSuccess = (users) => ({
    type: FETCH_USERS,
    payload: { users }
});

const addUserSuccess = (user) => ({
    type: ADD_USER,
    payload: { user }
});

const editUserSuccess = (user) => ({
    type: EDIT_USER,
    payload: { user }
});

const deleteUserSuccess = (userId) => ({
    type: DELETE_USER,
    payload: { userId }
});
export const fetchSubcontractorQRCodeSuccess = (qrCode) => ({
    type: FETCH_USER_QR_CODE,
    payload: { qrCodeUrl: qrCode }
});

// -------------------------------------------------------- ACTION CREATORS --------------------------------------------------------

export const fetchSubcontractors = () => async (dispatch) => {
    try {
        const response = await axiosServices.get(`/api/users?role=SUBCONTRACTOR`);
        dispatch(fetchUsersSuccess(response.data.data));
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const fetchEmployees = () => async (dispatch) => {
    try {
        const response = await axiosServices.get(`/api/users?role=EMPLOYEE`);

        dispatch(fetchUsersSuccess(response.data.data));
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const addUser = (userData) => async (dispatch) => {
    try {
        const response = await axiosServices.post('/api/users', userData);
        dispatch(addUserSuccess(response.data));
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Përdoruesi u shtua me sukses!',
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'success'
        });
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const editUser = (id, userData) => async (dispatch) => {
    try {
        const response = await axiosServices.patch(`/api/users/${id}`, userData);
        dispatch(editUserSuccess(response.data));
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Përdoruesi u përditësua me sukses!',
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'success'
        });
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const deleteUser = (id) => async (dispatch) => {
    try {
        await axiosServices.patch(`/api/users/${id}`, { isActive: false });
        dispatch(deleteUserSuccess(id));
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Përdoruesi u fshi me sukses!',
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'success'
        });
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};

export const fetchSubcontractorQrCode = (id) => async (dispatch) => {
    try {
        const response = await axiosServices.get(`/api/users/${id}/generateQrCode`);
        dispatch(fetchSubcontractorQRCodeSuccess(response.data.data));
    } catch (error) {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: error.message,
            variant: 'alert',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
    }
};
