import { FETCH_USERS, EDIT_USER, DELETE_USER, FETCH_USER_QR_CODE } from './userActions';

const initialState = {
    users: [],
    qrCode: null
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USERS:
            return {
                ...state,
                users: action.payload.users.docs
            };

        case EDIT_USER:
            return {
                ...state,
                users: Array.isArray(state.users)
                    ? state.users.map((user) => (user.id === action.payload.user.id ? action.payload.user : user))
                    : state.users
            };
        case DELETE_USER:
            return {
                ...state,
                users: state.users.filter((user) => user.id !== action.payload.userId)
            };
        case FETCH_USER_QR_CODE:
            return {
                ...state,
                qrCode: action.payload.qrCodeUrl
            };

        default:
            return state;
    }
};

export default userReducer;
